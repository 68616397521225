@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap");
// @import 'styles/exports.module.scss';
// @import 'styles/mapBoxStyles.scss';
// @import '~react-toastify/dist/ReactToastify.min.css';
html {
	scroll-behavior: smooth;
	/* GLOBAL STYLING */
	* {
		padding: 0;
		margin: 0;

		//! debug ghost elements
		// background: #000 !important;
		// color: #0f0 !important;
		// outline: solid #f00 1px !important;
	}

	*:focus {
		outline: 0;
	}

	a {
		text-decoration: none;
	}
	a:hover {
		text-decoration: none;
	}
	overflow: scroll;
	overflow-x: hidden;

	/* Customize scrollbar */
	// ::-webkit-scrollbar {
	// 	width: 5px;
	// }

	// ::-webkit-scrollbar-thumb {
	// 	background-color: $info;
	// 	outline: 1px solid $info;
	// }

	/* BODY STYLING */
	body {
		font-family: "Montserrat", sans-serif;
		// background: $primaryBg;
		// height: 100vh;

		.hero-image {
			height: 100vh;
		}

		/* common styling for all section */
		section {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}
